<template>
  <Layout>
    <Header>
      <CText color="white" :font-size="['md', 'lg', '2xl']">{{
        label("header_title")
      }}</CText>
    </Header>

    <CBox v-if="docs && docs.length" :p="4" max-width="1440px">
      <CFlex justify="space-between">
        <div>
          <c-breadcrumb>
            <c-breadcrumb-item>
              <c-breadcrumb-link href="https://www.wildspecies.ca/">{{
                label("home")
              }}</c-breadcrumb-link>
            </c-breadcrumb-item>

            <c-breadcrumb-item>
              <c-breadcrumb-link as="router-link" :to="`/${language}`">{{
                label("search")
              }}</c-breadcrumb-link>
            </c-breadcrumb-item>

            <c-breadcrumb-item isCurrentPage>
              <!-- This common name must come from the species_current table -->
              <c-breadcrumb-link href="#">{{
                currentCommonName(sc_doc, language)
              }}</c-breadcrumb-link>
            </c-breadcrumb-item>
          </c-breadcrumb>
        </div>
        <rank-modal-container opentext="Rank Definitions">
          <ENDefinitionOfRanks />
        </rank-modal-container>
      </CFlex>
      <CFlex wrap="wrap">
        <!-- Species Current -->

        <species-information-card
          :isDeleted="isDeleted"
          :docs="docs"
          :sc_doc="sc_doc"
          :mostRecentYear="speciesCurrent"
        />
        <CBox
          :width="['100%', '100%', '55%']"
          :mt="5"
          border="1px solid #E2E8F0"
        >
          <!-- MAP -->
          <CBox>
            <CTabs
              variant="enclosed-colored"
              is-fitted
              variant-color="green"
              size="sm"
              is-manual
              @change="onTabClick"
              border-bottom="1px solid #E2E8F0"
              :default-index="years.indexOf(Number($route.query.year))"
            >
              <CTabList>
                <CTab v-for="(year, i) in years" :key="i">{{ year }}</CTab>
              </CTabList>
            </CTabs>
          </CBox>
          <Map :species="speciesYear" />
        </CBox>
      </CFlex>

      <CBox width="100%">
        <CBox wrap="wrap" w="100%" :mt="4">
          <CBox :px="3" :pb="5">
            <CBox :mb="3">
              <TabTitle :title="label('common_names')" />
            </CBox>
            <CText as="Strong">{{ label("en_common_name") }}</CText>
            <CText as="span">{{ currentCommonName(sc_doc, "en") }} </CText>
            <br />
            <CText v-if="sc_doc">{{
              sc_doc[`en_justification_${language}`]
            }}</CText>
            <CText as="strong">{{ label("fr_common_name") }}</CText>
            <CText as="span">{{ currentCommonName(sc_doc, "fr") }} </CText>
            <CText v-if="sc_doc">{{
              sc_doc[`fr_justification_${language}`]
            }}</CText>
          </CBox>

          <DataTable
            :headings="[
              label('year'),
              label('en_common_name'),
              label('fr_common_name'),
            ]"
            :fields="['year', 'common_name_en', `common_name_fr`]"
            :rows="docs"
            hd_bg="none"
          />
        </CBox>

        <CBox :mt="5">
          <DataTable
            :title="label('scientific_names')"
            :headings="[
              label('year'),
              label('scientific_name'),
              label('family'),
              label('order'),
            ]"
            :fields="['year', 'scientific_name', `family`, `order`]"
            :rows="docs"
            hd_bg="none"
          >
            <!-- Higher Order -->
            <!--- Higher Order is joined to each report year from its own table. We just use the first, they will be all the same. -->
            <CBox :py="4">
              <CText as="span"
                ><strong>{{ label("higher_classification") }}: </strong></CText
              >
              <CText as="span" v-if="docs[0].superkingdom"
                ><CText as="span" color="green.600">{{
                  docs[0].superkingdom
                }}</CText>
                ({{ label("superkingdom") }})</CText
              >
              <CText as="span" v-if="docs[0].kingdom">
                >
                <CText as="span" color="green.600">{{ docs[0].kingdom }}</CText>
                ({{ label("kingdom") }})</CText
              >
              <CText as="span" v-if="docs[0].subkingdom">
                >
                <CText as="span" color="green.600">{{
                  docs[0].subkingdom
                }}</CText>
                ({{ label("subkingdom") }})</CText
              >
              <CText as="span" v-if="docs[0].infrakingdom">
                >
                <CText as="span" color="green.600">{{
                  docs[0].infrakingdom
                }}</CText>
                ({{ label("infrakingdom") }})</CText
              >
              <CText as="span" v-if="docs[0].superphylum">
                >
                <CText as="span" color="green.600">{{
                  docs[0].superphylum
                }}</CText>
                ({{ label("superphylum") }})</CText
              >
              <CText as="span" v-if="docs[0].phylum">
                >
                <CText as="span" color="green.600">{{ docs[0].phylum }}</CText>
                ({{ label("phylum") }})</CText
              >
              <CText as="span" v-if="docs[0].subphylum">
                >
                <CText as="span" color="green.600">{{
                  docs[0].subphylum
                }}</CText>
                ({{ label("subphylum") }})</CText
              >
              <CText as="span" v-if="docs[0].infraphylum">
                >
                <CText as="span" color="green.600">{{
                  docs[0].infraphylum
                }}</CText>
                ({{ label("infraphylum") }})</CText
              >
              <CText as="span" v-if="docs[0].superclass">
                >
                <CText as="span" color="green.600">{{
                  docs[0].superclass
                }}</CText>
                ({{ label("superclass") }})</CText
              >
              <CText as="span" v-if="docs[0].class_">
                >
                <CText as="span" color="green.600">{{ docs[0].class_ }}</CText>
                ({{ label("class_") }})</CText
              >
              <CText as="span" v-if="docs[0].subclass">
                >
                <CText as="span" color="green.600">{{
                  docs[0].subclass
                }}</CText>
                ({{ label("subclass") }})</CText
              >
              <CText as="span" v-if="docs[0].infraclass">
                >
                <CText as="span" color="green.600">{{
                  docs[0].infraclass
                }}</CText>
                ({{ label("infraclass") }})</CText
              >
              <CText as="span" v-if="docs[0].superorder">
                >
                <CText as="span" color="green.600">{{
                  docs[0].superorder
                }}</CText>
                ({{ label("superorder") }})</CText
              >
            </CBox>
          </DataTable>
        </CBox>

        <CBox :mt="5" :pl="3">
          <TabTitle :title="label('priority_score')" />
          <CText v-if="speciesCurrent.perc_range_in_canada" :pt="3"
            >{{ label("perc_canada") }}
            {{ speciesCurrent.perc_range_in_canada }}</CText
          >
          <CText v-if="speciesCurrent.priority" :pt="2"
            >{{ label("priority_score_explain") }}
            {{ speciesCurrent.priority }}</CText
          >
          <CText v-else :pt="2">{{ label("not_at_risk") }}</CText>
          <CText v-if="speciesCurrent[`why_${language}`]" :pt="2"
            >{{ label("why_at_risk") }}
            {{ speciesCurrent[`why_${language}`] }}</CText
          >
          <div v-if="speciesCurrent.assessed_by_cosewic === 'true'">
            <CText :pt="2"
              >{{ label("assessed") }}
              {{ boolean_codes["Yes"][language] }}</CText
            >
            <CLink
              color="green.500"
              :pt="2"
              :href="speciesCurrent[`cosewic_link_${language}`]"
              target="_blank"
            >
              {{ label("cosewic_report") }}
            </CLink>
          </div>

          <CText v-else-if="speciesCurrent.priority" :pt="2"
            >{{ label("assessed") }} {{ boolean_codes["No"][language] }}</CText
          >
        </CBox>

        <CFlex wrap="wrap" :mt="5">
          <CBox width="100%" :pt="3">
            <!-- Rank Text Year is supplied 2015 as a 'current' year as this field, in this place, uses the rounded national rank natureserve value, for which there is no historiacal value - even for 2010 -->
            <DataTable
              :headings="[
                label('year'),
                label('rounded_national_rank'),
                label('desc_change'),
                label('reason_change'),
                '',
              ]"
              :fields="[
                'year',
                'rounded_national_rank',
                `description_${language}`,
                'reason',
                `reason_extra_${language}`,
              ]"
              :rows="roundedNationalRanks"
              hd_bg="none"
              :title="label('trend_rank')"
            />
          </CBox>

          <CBox width="100%" :pt="5" :pl="3">
            <TabTitle :title="label('orig_ranks')" />
            <CFlex :wrap="['wrap']" mt="2">
              <!-- Loop original rank fields -->
              <CBox
                v-for="(rf, i) in original_rank_fields"
                :key="`${i}_orf`"
                :pr="5"
                :pb="4"
                font-size="sm"
                :width="['100%', '50%', '350px']"
              >
                <CText :px="3" as="strong">{{ regionLabel(rf) }}</CText>
                <CList :font-size="['xs', 'sm']">
                  <!-- Loop Years in fields -->
                  <CListItem
                    v-for="(report, index) in docs"
                    :key="`${index}_orr`"
                  >
                    <div v-if="showOriginalRankField(report.year, rf)">
                      <!-- If a value is returned, display it -->
                      <CText
                        v-if="rankTextYear(report.year, report[rf], language)"
                      >
                        <CDivider :m="1" border-color="gray.300" />
                        <CText :px="3">{{
                          `${report.year}: ${rankTextYear(
                            report.year,
                            report[rf],
                            language
                          )} ${rankCodeLabel(report[rf.replace("_map", "")])}`
                        }}</CText>
                      </CText>
                      <!-- Else default not-assessed on -->
                      <CText v-else>
                        <CDivider :m="1" border-color="gray.300" />
                        <CText :px="3" color="">{{
                          `${report.year}: ${label("not_present")}`
                        }}</CText>
                      </CText>
                    </div>
                  </CListItem>
                </CList>
              </CBox>
            </CFlex>
          </CBox>
        </CFlex>
      </CBox>
    </CBox>
  </Layout>
</template>

<script>
import { CBox, CText, CList, CListItem } from "@chakra-ui/vue";

import { mapState } from "vuex";
import {
  rank_codes,
  labels,
  origin,
  regions,
  original_rank_fields,
  boolean_codes,
} from "../config";
import { years } from "../config";

import { getSpeciesRecords, getSpeciesCurrent } from "../api";
import DataTable from "../components/DataTable.vue";
import SpeciesInformationCard from "../components/SpeciesProfile/SpeciesInformationCard.vue";

import Header from "../components/Header.vue";

import Map from "../components/Map.vue";
import TabTitle from "../components/TabTitle.vue";
import RankModalContainer from "../components/RankModalContainer.vue";

import utils from "../utils";

export default {
  mixins: [utils],
  components: {
    CBox,
    CText,
    CList,
    CListItem,
    Header,
    Map,
    DataTable,
    TabTitle,
    SpeciesInformationCard,
    RankModalContainer,
  },
  props: {
    name: String,
    clickedYear: Number,
    query_language: {
      default: "",
      type: String,
    },
  },
  data() {
    return {
      boolean_codes,
      labels,
      origin,
      docs: null,
      tabIndex: 0,
      selectedYear: 2020,
      sc_doc: null,
    };
  },
  activated() {
    this.sc_doc = null;
    this.docs = null;
    this.selectedYear = this.$route.query.year
      ? this.$route.query.year
      : "2020";
    this.code = this.$route.query.code;
    this.getSpecies(this.code);
  },
  mounted() {
    // this.sc_doc = null;
    // this.docs = null;
    this.code = this.$route.query?.code;
    if (!this.code) this.$router.push("/");
    this.getSpecies(this.code);
    this.setLanguage();
  },
  watch: {
    $route: function() {
      this.docs = [];
      this.sc_doc = {};
      this.getSpecies(this.$route.query.code);
    },
  },
  methods: {
    getSpecies(code) {
      getSpeciesRecords(code).then((response) => {
        this.docs = response.data;
      });
      getSpeciesCurrent(code).then((response) => {
        this.sc_doc = response.data;
      });
    },
    label(label) {
      return labels[label][this.language];
    },

    onTabClick(event) {
      this.tabIndex = event;
      this.selectedYear = this.years[event];
    },

    regionLabel(raw) {
      const value = raw.split("_")[0];
      const region = regions.find((rg) => rg.prefix === value);
      return region ? region[`label_${this.language}`] : "";
    },

    rankCodeLabel(label) {
      return label ? `(${label})` : "";
    },

    rankCode(code, year) {
      return code
        ? rank_codes[this.isHistorical(year)][code][`label_${this.language}`]
        : "";
    },

    rowColor(index) {
      return index % 2 ? "#ffffff" : "#c2ebd4";
    },

    showOriginalRankField(year, field) {
      // If year is less than 2010 and field is an NF or LB don't show it
      if (year <= 2010 && ["nf_map_rank", "lb_map_rank"].includes(field))
        return false;
      // If year is 2010 or gtr, don't show combined NL field.
      if (year > 2010 && field === "nl_map_rank") return false;
      return true;
    },

    setLanguage() {
      const lg = navigator.language;
      const browser_language_code = lg.substring(0, 2) === "fr" ? "fr" : "en";
      const language_code =
        this.query_language === "en" || this.query_language === "fr"
          ? this.query_language
          : browser_language_code;
      if (!this.query_language) {
        window.location.href = `/#/${language_code}`;
      }
      this.$store.dispatch("setLanguage", language_code);
    },
  },

  computed: {
    ...mapState({
      species: "selectedSpecies",
      language: "language",
      currentYear: "current_year",
    }),

    roundedNationalRanks() {
      // DO NOT CHANGE THE YEAR - the rounded national rank is only available for 2015 and newer data so a year > 2015 mucst be used.
      return this.docs.map((row) => ({
        ...row,
        rounded_national_rank: this.rankTextYear(
          2020,
          row.rounded_national_rank,
          this.language
        ),
        reason: row[`reason_${this.language}`],
      }));
    },

    speciesYear() {
      return this.docs.find((species) => species.year == this.selectedYear);
    },

    isDeleted() {
      return this.docs[0]?.description_code === "DEL";
    },

    speciesCurrent() {
      // return this.sc_doc;
      return this.docs?.length > 0 && !this.isDeleted
        ? this.docs[0]
        : this.docs[1];
    },

    years() {
      return this.docs.length > 0
        ? this.docs.map((doc) => doc.year)
        : years.map((item) => item.label);
    },

    original_rank_fields() {
      return original_rank_fields;
    },
  },
};
</script>

<style></style>
