<template>
  <CBox flex="1" height="auto" :my="5" :mr="[0, 0, 5]">
    <Card>
      <CBox v-if="mostRecentYear.year >= 2015 && !isDeleted">
        <!-- This common name must be the most recent. If it exists in speccies_current table use it, otherwise fall back to common name in ranks. -->
        <CText v-if="sc_doc" fontSize="xl" color="green.500">
          {{ currentCommonName(sc_doc, language) }}
        </CText>
        <CText fontSize="sm" as="i">
          {{ mostRecentYear[`scientific_name`] }}
        </CText>
        <CText color="gray.500" font-size="sm">{{
          label("species_code") + ": " + mostRecentYear.code
        }}</CText>
        <CDivider border-color="gray.300" />
        <CText fontSize="l" :pt="2">
          {{ `${label("general_status")} ${currentYear}: ` }}
          <CText as="strong">{{
            rankText(currentYear, mostRecentYear.ca_map_rank, language)
          }}</CText>
        </CText>
        <CText v-if="mostRecentYear.region_count">
          {{ `${label("present_in")} ${regionCount}` }}
          {{
            mostRecentYear.region_count == 1
              ? label("region_singular")
              : label("regions")
          }}
        </CText>
        <CText>
          {{ label("this_species_is")
          }}<strong>{{
            origin[mostRecentYear.ca_origin_code][`label_${language}`]
          }}</strong>
          {{ label("in_canada") }}
          <CText as="span" v-if="mostRecentYear.ca_origin_code === 'NAT'">
            <CText as="span" v-if="exoticRegionList.length > 0">{{
              label("but_exotic")
            }}</CText>
            <CText
              as="span"
              v-for="(item, index) in exoticRegionList"
              :key="index"
            >
              <CText as="span" v-if="index > 0">, </CText>
              {{ `${item}` }}
            </CText>
          </CText>
        </CText>
        <CText>
          {{ label("migratory_sp") }}
          <CText as="span">{{
            boolean_codes[mostRecentYear.ca_migratory][language]
          }}</CText>
        </CText>
        <CText>
          {{ label("taxonomic_group") + ": " }}
          <CText as="strong">{{
            mostRecentYear[`taxonomic_group_${language}`]
          }}</CText>
        </CText>
        <CText mb="4">
          <CLink
            color="green.500"
            :href="mostRecentYear[`uri_${language}`]"
            is-external
          >
            {{ label("learn_species") }}
            {{ mostRecentYear[`taxonomic_group_${language}`] }}
            {{ label("in_canada") }}.</CLink
          >
        </CText>
        <CText my="4" fontSize="sm" as="p">
          <sup>*</sup>{{ label("status_disclaimer") }}
        </CText>
      </CBox>
      <CBox v-else>
        <CText>{{ label("species_code") }}: {{ $route.query.code }}</CText>
        <CText v-if="sc_doc" fontSize="xl" color="green.500">
          {{ sc_doc[`common_name_${language}`] }}
        </CText>
        <CText fontSize="sm" as="i">
          {{ mostRecentYear[`scientific_name`] }}
        </CText>
        <CDivider border-color="gray.300" />
        <CText fontSize="l" :pt="2">
          {{
            `${label("general_status")} ${docs[0].year}: ${label(
              "not_present"
            )}`
          }}
        </CText>
      </CBox>
      <CText mt="4" v-if="mostRecentYear.year >= 2015 && !isDeleted">
        <CLink
          color="green.500"
          :href="ns_url(mostRecentYear.taxon_id)"
          is-external
          >{{ label("access_nse") }}</CLink
        >
      </CText>
    </Card>
  </CBox>
</template>

<script>
import Card from "../Card.vue";
import { labels } from "../../config";
import { mapState } from "vuex";
import utils from "../../utils";
import { regions } from "../../config";

export default {
  mixins: [utils],
  components: {
    Card,
  },
  props: {
    // This is the most recent year of data for the species.
    mostRecentYear: {
      type: Object,
      default: () => {},
    },
    // This is the common names for the species.
    sc_doc: {
      type: Object,
      default: () => {},
    },
    // All reports for the species.
    docs: {
      type: Array,
      default: () => [],
    },
    isDeleted: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    label(label) {
      return labels[label][this.language];
    },
  },
  computed: {
    ...mapState({
      species: "selectedSpecies",
      language: "language",
      currentYear: "current_year",
    }),
    // The number of regions the species is considered exotic in.
    exoticRegionList() {
      return this.province_origin_fields.reduce((accum, item) => {
        const origin = this.mostRecentYear[item];
        if (origin === "EXO") {
          const item_prefix = item.split("_")[0];
          const region = regions.find((rgn) => rgn.prefix === item_prefix);
          accum.push(region[`label_${this.language}`]);
        }
        return accum;
      }, []);
    },
    // Generate a list of all the field names with 'origin' for the currnet year and species.
    // This must be done dynamically because the field names are not consistent across years.
    province_origin_fields() {
      const field_names = Object.keys(this.docs[0]);
      const fields = field_names.filter(
        (field) => field.includes("_origin") && !field.includes("ca_")
      );
      return fields;
    },

    // How many regions is the species present in?
    regionCount() {
      return this.province_origin_fields.reduce((accum, item) => {
        const origin = this.mostRecentYear[item];
        if (origin === "EXO" || origin === "NAT") {
          accum += 1;
        }
        return accum;
      }, 0);
    },
  },
};
</script>
